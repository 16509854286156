import axios from 'axios' // 引入axios
import { emitter } from '@/utils/bus.js'
import {apiURL} from "@/config/settings";
import { Message, MessageBox } from 'element-ui'
import router from "@/router";

const service = axios.create({
    baseURL: apiURL,
    timeout: 99999
})
let acitveAxios = 0
let timer
const showLoading = () => {
    acitveAxios++
    if (timer) {
        clearTimeout(timer)
    }
    timer = setTimeout(() => {
        if (acitveAxios > 0) {
            emitter.emit('showLoading')
        }
    }, 400)
}

const closeLoading = () => {
        acitveAxios--
        if (acitveAxios <= 0) {
            clearTimeout(timer)
            emitter.emit('closeLoading')
        }
    }
    // http request 拦截器
service.interceptors.request.use(
    config => {
        if (!config.donNotShowLoading) {
            showLoading()
        }
        config.data = JSON.stringify(config.data)
        if (config.headers === undefined){
            config.headers = {
                'Content-Type':'application/json'
            }
        }else{
            config.headers['Content-Type']='application/json'
        }
        return config
    },
    error => {
        closeLoading()
        Message({
            showClose: true,
            message: error,
            type: 'error'
        })
        return error
    }
)

// http response 拦截器
service.interceptors.response.use(
    response => {
        closeLoading()
        if (response.data.code === 0 || response.headers.success === 'true') {
            if (response.headers.msg) {
                response.data.msg = decodeURI(response.headers.msg)
            }
            return response.data
        } else {
            Message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
            })
            return response.data.msg ? response.data : response
        }
    },
    error => {
        closeLoading()
        switch (error.response.status) {
            case 500:
                MessageBox.confirm(`
        <p>检测到接口错误${error}</p>
        <p>错误码<span style="color:red"> 500 </span>：请联系管理员修复 jorin@shaper3d.com</p>
        `, '接口报错', {
                        dangerouslyUseHTMLString: true,
                        distinguishCancelAndClose: true,
                        confirmButtonText: '清理缓存',
                        cancelButtonText: '取消'
                    })
                break
            case 404:
                MessageBox.confirm(`
          <p>接口不存在：${error}</p>
          <p>错误码<span style="color:red"> 404 </span>：页面被神秘力量吸走了，请联系管理员修复 jorin@shaper3d.com</p>
          `, '接口报错', {
                    dangerouslyUseHTMLString: true,
                    distinguishCancelAndClose: true,
                    confirmButtonText: '我知道了',
                    cancelButtonText: '取消'
                })
                break
            case 400:
                Message({
                    showClose: true,
                    message: error.response.data.non_field_errors[0],
                    type: 'error'
                })
                break
            case 401:
                if(router.currentRoute.name!=='login'){
                    router.push({name:"login"})
                }
                break
        }
        return error
    }
)

export default service
