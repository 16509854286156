// 编译配置-------------------------------------------------
// 后端服务器地址
export const apiURL = "https://seal.shouheng.com/server";
// 本应用当前的地址
export const isDevelopEnv = false;
// 编译配置结束----------------------------------------------

// // 本地开发配置 ------------------------------------------
// // -----------------------------------------------------
// // 本地测试用：
// export const apiURL = "http://localhost:8000";
// // 启用开发环境
// export const isDevelopEnv = true;
// // -----------------------------------------------------
// // 开发配置结束-------------------------------------------

// 计算时间戳是否到期时的偏移值，以秒为单位，负值为提前多少
export const timestampOffset = -600;
