export function getAuth(str) {
  // return localStorage.getItem('sfl-authority') || ['admin', 'user'];
  const authString =
    typeof str === "undefined" ? localStorage.getItem("sfl-authority") : str;

  let auth;

  try {
    if (authString) {
      auth = JSON.parse(authString);
    }
  } catch (e) {
    auth = authString;
  }
  return auth;
}

export function setAuth(authority) {
  const proAuthority = typeof authority === "string" ? [authority] : authority;
  return localStorage.setItem("sfl-authority", JSON.stringify(proAuthority));
}
