import service from '@/utils/request'
import {apiURL} from "@/config/settings";

// @Summary 通过唯一标识获取设置
// @Produce  application/json
// @Router /auth/login/
export const login = (username,password) => {
    return service({
        url: `${apiURL}/auth/login/`,
        method: 'post',
        data:{
            username,
            password
        }
    })
}

// @Summary 检查 token 的有效性
// @Produce  application/json
// @Router /check_token/
export const check_token = token => {
    return service({
        url: `${apiURL}/get_user/`,
        headers: { Authorization: `token ${token}` },
        data:{},
        method: 'post',
    })
}

// @Summary 通过文字搜索内容
// @Produce  application/json
// @Router /search_character/{char}/
export const search = (token ,character, page=null) => {
    let url = `${apiURL}/search_character/${character}/`
    if (page!==null){
        url += `?page=${page}`
    }
    return service({
        url: url,
        headers: { Authorization: `token ${token}` },
        method: 'get',
    })
}

// GET 访问任意 url
export const get_any_url = (url, token=null) =>{
    let conf = {
        url: url,
        method: 'get',
    }
    if (token!==null){
        conf['headers'] = { Authorization: `token ${token}` }
    }
    return service(conf)
}

