import "typeface-roboto/index.css";
import "@mdi/font/css/materialdesignicons.css";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Input } from "element-ui";

import vuetify from '@/plugins/vuetify'

Vue.config.productionTip = false
Vue.use(Input)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
