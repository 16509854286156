import { setAuth, getAuth } from "@/utils/authority";
import {check_token} from "@/service/api";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        token: "", // 保存登录 token
        username: "",
        userid: -1, // 在系统中对应登录用户的 ID
        is_staff: false,
        token_invalid: false
    },
    mutations: {
        // 设置通过登录的用户信息
        set_user(state, data) {
            // 先把 token 保存到本地
            setAuth(data);
            // 设置 token、昵称、用户ID、微信用户ID、头像等。
            state.token = data.token;
            state.username = data.user.username;
            state.userid = data.user.id;
            state.is_staff = data.is_staff;
            state.token_invalid = false;
        },
        invalid_token(state){
            state.token_invalid = true
        }
    },
    actions: {
        get_and_check_local_user(context) {
            let auth = getAuth();
            let noAuth = auth === undefined;
            if (!noAuth && !context.state.token_invalid) {
                check_token(auth.token).then(result=>{
                    if(result.code !== undefined && result.code === 0){
                        context.commit("set_user", auth)
                        router.push({name:"search"})
                        return
                    }
                    context.commit("invalid_token")
                })
            }
        }
    },
    getters: {
    }
}
